<template>
  <div class="admin-wrap">
    <AdminMenu active-item="staffs" />
    <div class="admin-main">
      <AdminHeader title="員工管理" backlink="javascript:history.back()" />
      <div class="employee-detail">
        <div class="employee-detail__basic-info">
          <div class="employee-detail__basic-info-inner">
            <div class="employee-detail__col">
              <h3>基本資料</h3>
              <div class="employee-detail__form">
                <div class="employee-detail__row">
                  <label for="name">姓名</label>
                  <input type="text" id="name" v-model="staff.fullName">
                </div>
                <div class="employee-detail__row">
                  <label for="id-number">身分證</label>
                  <input type="text" id="id-number" v-model="staff.idNumber">
                </div>
                <div class="employee-detail__row">
                  <label for="gender">性別</label>
                  <div class="client-detail__select-wrap">
                    <select v-model="staff.gender">
                      <option value="MALE">男</option>
                      <option value="FEMALE">女</option>
                    </select>
                  </div>
                </div>
                <div class="employee-detail__row">
                  <label for="phone">電話</label>
                  <input type="tel" id="phone" v-model="staff.phone">
                </div>
                <div class="employee-detail__row">
                  <label for="address">地址</label>
                  <input type="text" id="address" v-model="staff.address">
                </div>
                <div class="employee-detail__row">
                  <label for="picture">照片</label>
                  <div class="employee-detail__avatar">
                    <div class="employee-detail__picture-wrap">
                    <img :src="photo" alt="">
                    </div>
                    <input type="file" @change="handleImage" accept="image/*">
                  </div>
                </div>
              </div>
            </div>
            <div class="employee-detail__col">
              <h3>當月工作統計</h3>
              <div class="employee-detail__work-list">
                <p>任務總數：<strong>{{workSummary.totalTasks}}</strong></p>
                <p>準時完成數量：<strong>{{workSummary.totalOntime}}</strong></p>
                <p>逾時完成數量：<strong>{{workSummary.totalOverdue}}</strong></p>
                <p>未完成數量：<strong>{{workSummary.totalUnfinished}}</strong></p>
              </div>
              <a class="employee-detail__btn-report" :href="`/admin/staffs/${staff.id}/report`">前往報表</a>
            </div>
          </div>
        </div>
        <div class="employee-detail__setting">
          <h3>身份與專長設定</h3>
          <div class="employee-detail__setting-form">
            <div class="employee-detail__row">
              <label for="identity">身份設定</label>
              <div class="employee-detail__select-wrap">
                <select name="identity" id="identity" v-model="staff.jobId">
                  <option v-for="job in jobOptions" :key="job" :value="job.id">{{job.name}}</option>
                </select>
              </div>
            </div>
            <div class="employee-detail__row">
              <label for="skill">專長設定</label>
              <div class="employee-detail__skill-wrap">
                <div class="employee-detail__skill-item" v-for="specialty in specialtyOptions" :key="specialty">
                  <input type="checkbox" :checked="selectedSpecialtiesId.includes(specialty.id)" @change="onSpecialtyChange(specialty.id)">
                  <label for="skill-01">{{specialty.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <button class="admin-btn-lg" @click="onSaveBasicInfo">儲存</button>
        </div>
        <div class="employee-detail__setting" v-if="staff.id > 0">
          <h3>服務流程設定</h3>
          <div class="employee-detail__flow">
            <table class="employee-detail__flow-table">
              <thead>
                <tr>
                  <th></th>
                  <th>1報到</th>
                  <th>2卸洗</th>
                  <th>3拍照</th>
                  <th>4敷麻</th>
                  <th>5卸麻</th>
                  <th>6醫師處置</th>
                  <th>7-1術後鎮定</th>
                  <th>7-2術後鎮定(卸面膜)</th>
                  <th>8預約|衛教</th>
                  <th>9送客</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tt, index) in treatmentTimes" :key="index">
                  <td>{{tt.treatmentName}}</td>
                  <td>
                    <input type="text" v-model="tt.checkInTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.washTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.photoTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.anesthesiaTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.postAnesthesiaTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.doctorTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.sedativesTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.sedatives2Time">
                  </td>
                  <td>
                    <input type="text" v-model="tt.appointmentTime">
                  </td>
                  <td>
                    <input type="text" v-model="tt.byeTime">
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="admin-btn-lg" @click="onSaveTreatmentTime">儲存</button>
          </div>
        </div>
        <div class="employee-detail__setting" v-if="staff.id > 0">
          <h3>上下班時間設定</h3>
          <div class="employee-detail__working-hours">
            <table class="employee-detail__working-hours-table">
              <thead>
                <tr>
                  <th></th>
                  <th>上班時間</th>
                  <th></th>
                  <th>下班時間</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>週一</td>
                  <td>
                    <input type="time" v-model="workingHours.monStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.monEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.monStart=workingHours.monEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週二</td>
                  <td>
                    <input type="time" v-model="workingHours.tueStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.tueEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.tueStart=workingHours.tueEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週三</td>
                  <td>
                    <input type="time" v-model="workingHours.wedStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.wedEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.wedStart=workingHours.wedEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週四</td>
                  <td>
                    <input type="time" v-model="workingHours.thuStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.thuEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.thuStart=workingHours.thuEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週五</td>
                  <td>
                    <input type="time" v-model="workingHours.friStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.friEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.friStart=workingHours.friEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週六</td>
                  <td>
                    <input type="time" v-model="workingHours.satStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="workingHours.satEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="workingHours.satStart=workingHours.satEnd='00:00'">清除</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="admin-btn-lg" @click="onSaveWorkingHours">儲存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { apiGetStaff, photoUrl, apiGetSpecialties, apiGetJobs, apiSaveStaff, apiGetWorkingHours, apiSetWorkingHours, apiGetTreatmentTimes, apiSetTreatmentTimes, apiGetWorkSummary } from '@/api/v1/admin'

export default {
  name: 'StaffDetail',
  props: ['staffId'],
  components: { AdminMenu, AdminHeader },
  data () {
    return {
      staff: '',
      photo: '',
      isUploadPhoto: false,
      jobOptions: [],
      specialtyOptions: [],
      selectedSpecialtiesId: [],
      workingHours: '',
      treatmentTimes: [],
      workSummary: ''
    }
  },
  computed () {

  },
  created () {
    this.fetchData()
  },
  watch: {
    staff: function (newStaff) {
      // load working hours if staffid > 0
      if (newStaff.id > 0) {
        apiGetWorkingHours(newStaff.id).then(data => {
          console.log(data)
          if (!data.error) {
            this.workingHours = data
          }
        })
        // load treatment times
        apiGetTreatmentTimes(newStaff.id).then(data => {
          console.log(data)
          if (!data.error) {
            this.treatmentTimes = data
          }
        })
        // Load work summary
        apiGetWorkSummary(newStaff.id).then(data => {
          console.log(data)
          if (!data.error) {
            this.workSummary = data
          }
        })
      }
    }
  },
  methods: {
    fetchData () {
      if (this.staffId > 0) {
        apiGetStaff(this.staffId).then(data => {
          if (data.id) {
            this.staff = data
            this.selectedSpecialtiesId = data.specialties.map(m => m.id)
            if (data.photo) {
              this.photo = photoUrl('staffs', this.staff.id)
            }
          }
        })
      } else {
        // new staff
        this.staff = {
          id: 0,
          fullName: '',
          idNumber: '',
          gender: 'MALE',
          phone: '',
          address: '',
          jobId: 1
        }
      }
      apiGetSpecialties().then(data => {
        console.log(data)
        if (!data.error) {
          this.specialtyOptions = data
        }
      })
      apiGetJobs().then(data => {
        console.log(data)
        if (!data.error) {
          this.jobOptions = data
        }
      })
    },
    handleImage (e) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.photo = e.target.result
        this.isUploadPhoto = true
      }
      reader.readAsDataURL(e.target.files[0])
    },
    onSpecialtyChange (specialtyId) {
      const i = this.selectedSpecialtiesId.indexOf(specialtyId)
      if (i === -1) {
        this.selectedSpecialtiesId.push(specialtyId)
      } else {
        this.selectedSpecialtiesId.splice(i, 1)
      }
    },
    onSaveBasicInfo () {
      if (!this.staff.idNumber || (this.staff.idNumber.length !== 10)) {
        this.$swal('資料錯誤', '請填寫正確身分證字號', 'warning')
        return
      }
      const params = {
        id: this.staff.id,
        fullName: this.staff.fullName,
        idNumber: this.staff.idNumber,
        gender: this.staff.gender,
        phone: this.staff.phone,
        address: this.staff.address,
        jobId: this.staff.jobId,
        specialtyIds: JSON.parse(JSON.stringify(this.selectedSpecialtiesId))
      }
      if (this.isUploadPhoto) {
        params.photoContent = this.photo
      }
      apiSaveStaff(params).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('儲存失敗', data.error.message, 'warning')
        } else {
          this.staff = data
          this.$swal('儲存完成', '', 'success')
        }
      }).catch(err => {
        this.$swal('儲存失敗', err.data.error, 'warning')
      })
    },
    onSaveWorkingHours () {
      if (this.staff.id > 0) {
        apiSetWorkingHours(this.staff.id, this.workingHours).then(data => {
          console.log(data)
          if (data.error) {
            this.$swal('儲存失敗', data.error.message, 'warning')
          } else {
            this.$swal('儲存完成', '', 'success')
          }
        }).catch(err => {
          this.$swal('儲存失敗', err.data.error, 'warning')
        })
      }
    },
    onSaveTreatmentTime () {
      if (this.staff.id > 0) {
        apiSetTreatmentTimes(this.staff.id, this.treatmentTimes).then(data => {
          console.log(data)
          if (data.error) {
            this.$swal('儲存失敗', data.error.message, 'warning')
          } else {
            this.$swal('儲存完成', '', 'success')
          }
        }).catch(err => {
          this.$swal('儲存失敗', err.data.error, 'warning')
        })
      }
    }
  }
}

</script>

<style>

</style>
