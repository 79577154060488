<template>
  <div class="admin-header">
    <a class="admin-header__btn-back" :href="backlink" v-if="backlink">返回</a>
    <h2>{{title}}</h2>
    <div class="admin-header__control">
      <div class="admin-header__account">
        <img class="admin-header__account-avatar" :src="photo" :onerror="loadDefaultPhoto()">
        <span class="admin-header__account-name">{{username}}</span>
        <button class="admin-header__btn-logout" @click="logout()">登出</button>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/service/auth.service'

export default {
  name: 'AdminHeader',
  props: ['title', 'backlink'],
  data () {
    return {
      photo: ''
    }
  },
  computed: {
    username () {
      return this.$store.state.auth.user ? this.$store.state.auth.user.full_name : ''
    }
  },
  methods: {
    loadDefaultPhoto () {
      this.photo = (this.$store.state.auth.user.gender === 1 ? require('@/assets/images/id-pic-man.svg') : require('@/assets/images/id-pic-woman.svg'))
    },
    logout () {
      AuthService.logout('AdminLogin')
    }
  }
}
</script>

<style>

</style>
