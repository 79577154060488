import req from '@/api/v1/request-admin'
import { baseURL } from '@/api/v1/host'
// Login
export const apiLogin = params => req.post('auth/login', params)
// Logout
export const apiLogout = () => req.post('auth/logout')
// 客戶列表
export const apiListPatients = (params) => req.get('patients', params)
// 客戶分類
export const apiGetPatientTypes = () => req.get('patient_types')
// 單一客戶
export const apiGetPatient = patientId => req.get(`patients/${patientId}`)
// 客戶預約紀錄
export const apiGetReservationHistory = params => req.get(`patients/${params.patientId}/reservations`, params.data)
// 建立新客戶
export const apiCreatePatient = params => req.post('patients', params.data)
// 更新客戶
export const apiUpdatePatient = params => req.put(`patients/${params.patientId}`, params.data)
// 刪除客戶
export const apiDeletePatient = id => req.delete(`patients/${id}`)
// 員工列表
export const apiGetStaffs = params => req.get('staffs', params)
// 員工（個別）資料
export const apiGetStaff = id => req.get(`staffs/${id}`)
// 儲存員工
export const apiSaveStaff = params => req.post(`staffs/${params.id}`, params)
// 刪除員工
export const apiDeleteStaff = id => req.delete(`staffs/${id}`)
// 取得上班時間
export const apiGetWorkingHours = id => req.get(`staffs/${id}/workinghours`)
// 儲存上班時間
export const apiSetWorkingHours = (staffId, params) => req.post(`staffs/${staffId}/workinghours`, params)
// 員工療程時間
export const apiGetTreatmentTimes = id => req.get(`staffs/${id}/treatment-times`)
// 設定員工療程時間
export const apiSetTreatmentTimes = (staffId, params) => req.post(`staffs/${staffId}/treatment-times`, params)
// 月工作統計
export const apiGetWorkSummary = (id, params) => req.get(`staffs/${id}/work-summary`, params)
// 工作紀錄
export const apiGetTasks = (id, params) => req.get(`staffs/${id}/tasks`, params)
// 身份列表
export const apiGetJobs = () => req.get('jobs')
// 專長列表
export const apiGetSpecialties = () => req.get('specialties')
// 場地列表
export const apiGetSpots = params => req.get('spots', params)
// 場地（單一）
export const apiGetSpot = params => req.get(`spots/${params.spotId}`)
// 儲存場地
export const apiSaveSpot = params => req.post(`spots/${params.id}`, params)
// 刪除場地
export const apiDeleteSpot = id => req.delete(`spots/${id}`)
// 場地照片
export const spotPhotoUrl = (spotId) => (`${baseURL}/spots/${spotId}/photo`)
// 組合照片網址（what == staffs or spots or patients)
export const photoUrl = (what, id) => (`${baseURL}/${what}/${id}/photo`)
export const qrcodeUrl = (what, id) => (`${baseURL}/${what}/${id}/qrcode`)
// 取得療程分類
export const apiGetTreatmentTypes = () => req.get('treatment_types')
export const apiGetTreatmentType = (id) => req.get(`treatment_types/${id}`)
export const apiSaveTreatmentType = params => req.post(`treatment_types/${params.id}`, params)
export const apiDeleteTreatmentTypes = (id) => req.delete(`treatment_types/${id}`)
// 療程
export const apiGetTreatments = () => req.get('treatments')
export const apiGetTreatment = (id) => req.get(`treatments/${id}`)
export const apiGetTreatmentJobsAndSpecialties = (id) => req.get(`treatments/${id}/jobs-specialties`)
export const apiDeleteTreatment = (id) => req.delete(`treatments/${id}`)
export const apiSaveTreatment = (id, params) => req.post(`treatments/${id}`, params)
// 休假
export const apiGetVacation = (date) => req.get(`vacation/${date}`)
export const apiSetVacation = (date, payload) => req.post(`vacation/${date}`, payload)
// User (Account)
export const apiGetUsers = () => req.get('users')
export const apiGetUser = (id) => req.get(`users/${id}`)
export const apiDeleteUser = (id) => req.delete(`users/${id}`)
export const apiCreateUser = params => req.post('users', params)
export const apiUpdateUser = params => req.put(`users/${params.id}`, params)
// Roles
export const apiGetRoles = () => req.get('roles')
// Report
export const apiGetTreatmentRanking = params => req.get('report/rankings/treatment', params)
export const apiGetDoctorRanking = params => req.get('report/rankings/doctor', params)
export const apiGetVIPRanking = params => req.get('report/rankings/vip', params)
export const apiGetDailyCount = params => req.get('report/rankings/daily', params)
// 總案件數
export const apiGetTotal = params => req.get('report/total', params)
// 案件列表
export const apiGetReportReservations = params => req.get('report/reservations', params)
// 時間管理
export const apiGetSystemSettings = () => req.get('system/settings')
export const apiSetSystemSettings = params => req.post('system/settings', params)
// 連續指派 - List
export const apiGetTreatmentFlowSeries = (treatmentId) => req.get(`/treatments/${treatmentId}/series`)
export const apiAddTreatmentFlowSeries = params => req.post(`/treatments/${params.treatmentId}/series`, params)
export const apiDeleteTreatmentFlowSeries = (treatmentId, seriesId) => req.delete(`/treatments/${treatmentId}/series/${seriesId}`)
