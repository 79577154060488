/**
 * Authentication Service
 */
import { apiLogin, apiLogout } from '@/api/v1/auth'
import store from '../store'
import router from '../router'

export const AuthService = {
  login: function (withCredentials) {
    return apiLogin(withCredentials).then(data => {
      if (data.access_token) {
        store.dispatch('auth/onLogin', data)
        localStorage.setItem('user', JSON.stringify(data))
      } else {
        localStorage.removeItem('user')
        store.dispatch('auth/onLogout')
      }
      return data
    })
  },
  logout: function (to = 'Login') {
    apiLogout().then(() => {
      localStorage.removeItem('user')
      store.dispatch('auth/onLogout')
      router.replace({ name: to })
    })
  }
}
