<template>
  <div class="side-bar">
    <div class="side-bar__logo">
      <img src="@/assets/images/admin-logo.jpg" alt="" />
    </div>
    <div class="side-bar__nav">
      <ul>
        <li>
          <router-link class="side-bar__btn" :class="{'is-active':(activeItem === 'staffs')}"  to="/admin/staffs">員工管理</router-link>
        </li>
        <li>
          <router-link class="side-bar__btn" :class="{'is-active':(activeItem === 'spots')}" to="/admin/spots">診間管理</router-link>
        </li>
        <li>
          <a class="side-bar__btn side-bar__btn--sub" @click="onToggleServiceExpanded"  :class="{'is-active':(activeItem.includes('treatment'))}">服務管理</a>
          <div class="side-bar__sub-nav" :class="{'is-active':isServiceExpanded}" >
            <router-link to="/admin/treatment-types" :class="{'is-active':(activeItem === 'treatment-types')}">類別管理</router-link>
            <router-link to="/admin/treatments" :class="{'is-active':(activeItem === 'treatments')}">療程管理</router-link>
            <router-link to="/admin/treatment-time" :class="{'is-active':(activeItem === 'treatment-time')}">時間管理</router-link>
          </div>
        </li>
        <!-- <li>
          <a class="side-bar__btn side-bar__btn--sub" @click="onToggleServiceExpanded" :class="{'is-active':(activeItem.includes('infusion'))}">點滴管理</a>
          <div class="side-bar__sub-nav" :class="{'is-active':isServiceExpanded && activeItem.includes('infusion')}" >
            <a href="/admin/infusions" :class="{'is-active':(activeItem === 'infusions')}">點滴管理</a>
            <a href="/admin/infusion-ingredients" :class="{'is-active':(activeItem === 'infusion-ingredients')}">附加配方管理</a>
          </div>
        </li> -->
        <li>
          <router-link class="side-bar__btn" to="/admin/vacation" :class="{'is-active':(activeItem === 'vacation')}">休假管理</router-link>
        </li>
        <li v-if="isAdmin">
          <router-link class="side-bar__btn" :class="{'is-active':(activeItem === 'clients')}" to="/admin/clients">客戶管理</router-link>
        </li>
        <li v-if="isAdmin">
          <router-link class="side-bar__btn" to="/admin/users" :class="{'is-active':(activeItem === 'users')}">帳號管理</router-link>
        </li>
        <li v-if="isAdmin">
          <router-link class="side-bar__btn" to="/admin/overall-report" :class="{'is-active':(activeItem === 'overall-report')}">總體報表</router-link>
        </li>
        <li v-if="isAdmin">
          <router-link class="side-bar__btn" to="/admin/work-detail" :class="{'is-active':(activeItem === 'work-detail')}">明細查詢</router-link>
        </li>
      </ul>
    </div>
    <button class="side-bar__btn-logout" @click="logout()">登出</button>
  </div>
</template>
<script>
import { AuthService } from '../service/auth.service'

export default {
  name: 'AdminMenu',
  props: ['activeItem'],
  data () {
    return {
      isServiceExpanded: false
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    isAdmin () {
      return this.user ? this.user.roles[0].includes('ROLE_ADMIN') : false
    }
  },
  methods: {
    onToggleServiceExpanded () {
      this.isServiceExpanded = !this.isServiceExpanded
    },
    logout () {
      AuthService.logout('AdminLogin')
    }
  }
}
</script>
